export const MESSAGES = {
  confirmLogout:  'Are you sure you want to logout?',
  confirmCreate:  'Are you sure you want to create this {item}?',
  confirmImport:  'Are you sure you want to import this {item}?',
  confirmUpdate:  'Are you sure you want to edit this {item}?',
  confirmDelete:  'Are you sure you want to delete this {item}?',
  confirmDisable:  'Are you sure you want to disable this {item}?',
  confirmEnable:  'Are you sure you want to enable this {item}?',
  confirmDeleteWithName: 'Are you sure you want delete this {module}: {item}?',
  pageNotFoundTitle: 'Sorry, the page you visited does not exist.',
  successCreate: '{item} has been successfully created.',
  successUpdate: '{item} has been successfully updated.',
  successDisable: '{item} has been successfully disabled.',
  successEnable: '{item} has been successfully enabled.',
  successDelete: '{item} has been successfully deleted.',
  successImport: '{item} successfully imported.',
  unauthorizedAppAccessTitle: 'You are not authorized to access this app.',
  invalidFile: 'Invalid file type.',
  viewSingleItemTip: 'To continue, click on any item from the list on the left for more details.',
  noInspections: 'No inspection types found.',
  noIssues: 'No issues found for this asset.',
  noComponents: 'No components found for this asset.',
  noUsers: 'No users found.',
  noCustomerAccounts: 'No customer accounts found.',
  noCustomerAdminAccounts: 'No customer admin accounts found.',
  noOpenIssues: 'No open issues found for this asset.',
  // Assets
  noAssets: 'No assets found.',
  csvFileOnly: 'Only .csv files are allowed, please try again.',
  // Issues
  noIssuesFound: 'No issues found',
  confirmAssignToMaintenance: 'Are you sure you want to assign this issue to maintenance?',
  successAssignToMaintenance: 'Issue has been assigned to maintenance.',
  confirmSetSeverity: 'Are you sure you want set this severity status to {item}?',
  successSetSeverity: 'Severity status successfully set to {item}.',
  confirmSetForInspectorValidation: 'Are you sure you want to set this issue as ready for inspector validation?',
  successSetForInspectorValidation: 'Issue has been set as ready for validation.',
  confirmMarkAsNoFault: 'Are you sure you want to mark this issue as No Fault?',
  successMarkAsNoFault: 'Issue has been set as No Fault',
  // Components
  noComponentsFound: 'No components found',
  confirmDeleteComponent: 'Are you sure you want delete this component: {item}?',
  successDeleteComponent: 'Component has been successfully deleted.',
  successCreateComponent: 'Component has been successfully created.',
  successEditComponent: 'Component has been successfully edited.',
  selectTypes: 'Select one or more types...',
  noSymptoms: 'No symptoms listed for this component.',
  // Checklist
  noWorkingComponents: 'No working components.',
  noChecklist: 'No checklist history.',
  // Notifications
  noNotifications: 'No notifications.'
};

export const FIELD_ERROR = {
  required: 'This field is required.',
  minLength: 'This field is required.',
  alphaNumeric: 'Only alphanumeric characters are allowed.',
  alphaNumericSpace: 'Only alphanumeric characters and space are allowed.',
  alphaNumericSpaceDash: 'Only alphanumeric characters, dash, and space are allowed.',
  alphaNumericDash: 'Only alphanumeric characters and dash are allowed.',
  minComponent: 'Atleast one component is required.',
  unique: 'This is already an existing symptom.',
  notEmpty: 'This field is required.',
  email: 'Invalid email address.',
  maxLength: 'Maximum of {{1}} characters are allowed.',
  nonUniqueAreaName: 'Area names should be unique for each inspection type.',
  numeric: 'Only numbers are allowed.',
  minEndDateLicense: 'End date must be atleast one month after the start date.',
}

export const constructMessage = (message: string, replacement: string) =>
  message.replace('{item}', replacement);

export const constructMessageWithItem = (message: string, module: string, item: string) =>
  {
    const newMessage = message.replace('{item}', item);
    return newMessage.replace('{module}', module);
  };
