export const DATE_FORMAT = {
  DEFAULT: 'dd/MM/yyyy',
  DATETIME_12_HOUR_WITH_SECONDS: 'dd/MM/yyyy hh:mm:ss a',
  DATETIME_12_HOUR: 'dd/MM/yyyy hh:mm a',
  TIME_24_HOUR: 'HH:mm',
  TIME_12_HOUR: 'HH:mm a',
  DATETIME: `dd/MM/yyyyTHH:mm:ssZ`,
  DATETIME_24_HOUR: 'dd/MM/yyyy HH:mm',
  YEAR_MONTH: 'yyyy-MM',
  YEAR: 'yyyy',
  MONTH_DAY: 'MMM d',
  DAY_MONTH: 'dd-MMM',
  ISO_DATE: 'yyyy-MM-dd',
}
