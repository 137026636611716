import { PERMISSIONS } from '@shared/enums';
import { ngxPermissionsGuard } from 'ngx-permissions';

export const PERM_GROUPS = {
  SHLONAK: [
    PERMISSIONS.ADMIN_PORTAL_USER
  ],
  BACK_OFFICE: [
    PERMISSIONS.BACK_OFFICE_USER_ADMIN,
    PERMISSIONS.BACK_OFFICE_USER_SUPERVISOR,
    PERMISSIONS.BACK_OFFICE_USER_VIEW
  ],
  BACK_OFFICE_VIEW: [
    PERMISSIONS.BACK_OFFICE_USER_VIEW
  ],
  BACK_OFFICE_ADMIN: [
    PERMISSIONS.BACK_OFFICE_USER_ADMIN,
    PERMISSIONS.BACK_OFFICE_USER_VIEW
  ]
}

export const BO_PERM_DATA = {
  canActivate: [ngxPermissionsGuard],
  data: {
    permissions: {
      only: PERM_GROUPS.BACK_OFFICE,
      redirectTo: '/unauthorized'
    }
  }
}

export const ADMIN_PERM_DATA = {
  canActivate: [ngxPermissionsGuard],
  data: {
    permissions: {
      only: PERM_GROUPS.SHLONAK,
      redirectTo: '/unauthorized'
    }
  }
}


