export const UserGroups = {
  Driver: '/Mobile/Drivers',
  Inspector: '/Mobile/Inspectors',
  Admin: '/Back Office/Admin',
  Supervisor: '/Back Office/Supervisors'
}

export const NonAdminUserGroups = {
  Driver: '/Mobile/Drivers',
  Inspector: '/Mobile/Inspectors',
  Supervisor: '/Back Office/Supervisors'
}

export const UserGroupLabel: Record<string, string> = {
  [UserGroups.Admin]: 'Admin',
  [UserGroups.Driver]: 'Driver',
  [UserGroups.Inspector]: 'Inspector',
  [UserGroups.Supervisor]: 'Supervisor',
}
