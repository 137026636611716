export const MENU = {
  dashboard: 'Dashboard',
  assets: 'Assets',
  issues: 'Issues',
  users: 'Users',
  components: 'Components',
  inspections: 'Inspections',
  settings: 'Settings',
  notifications: 'Notifications',
  profile: 'Profile',
  customerAccounts: 'Customer Accounts',
  checklists: 'Checklists',
};

export const ACTIONS = {
  yes: 'Yes',
  no: 'No',
  logout: 'Logout',
  create: 'Create',
  generate: 'Generate',
  confirmation: 'Confirmation',
  update: 'Update',
  edit: 'Edit',
  delete: 'Delete',
  submit: 'Submit',
  cancel: 'Cancel',
  close: 'Close',
  clickToUpload: 'Click to Upload',
  assignToMaintenance: 'Assign to Maintenance',
  search: 'Search',
  clear: 'Clear',
  reset: 'Reset',
  readyForInspectorValidation: 'Ready for Inspector Validation',
  noFault: "No Fault",
  add: 'Add',
  view: 'View',
  selectAll: 'Select All',
  selectCurrentPage: 'Current Page Only',
  back: 'Back',
  viewAll: 'View All',
  disable: 'Disable',
  enable: 'Enable',
  print: 'Print',
  markAsRead: 'Mark as Read',
  markAsUnread: 'Mark as Unread',
}

export const LABELS = {
  asset: 'Asset',
  assets: 'Assets',
  assetId: 'Asset ID',
  make: 'Make',
  model: 'Model',
  image: 'Image',
  licensePlate: 'License Plate',
  totalMileage: 'Total Mileage',
  status: 'Status',
  success: 'Success',
  error: 'Error',
  listOfIssues: 'List of Issues',
  issue: 'Issue',
  issueId: 'Issue ID',
  reported: 'Reported',
  reportedBy: 'Reported by',
  description: 'Description',
  severity: 'Severity',
  setSeverity: 'Set Severity',
  photos: 'Photos',
  advancedSearch: 'Advanced Search',
  component: 'Component',
  dateReported: 'Date Reported',
  from: 'From',
  to: 'To',
  type: 'Type',
  types: 'Types',
  inspection: 'Inspection',
  name: 'Name',
  area: 'Area',
  new: 'New',
  symptom: 'Symptom',
  symptoms: 'Symptoms',
  openIssues: 'Open Issues',
  reasonForNoFault: 'Reason for No Fault',
  qrCode: 'QR Code',
  location: 'Location',
  reasonForRework: 'Reason for Rework',
  dateValidated: 'Date Validated',
  validatedBy: 'Validated by',
  dateReworked: 'Date of Rework',
  reworkedBy: 'Reworked by',
  by: 'By',
  setBy: 'Set by',
  history: 'History',
  timeline: 'Timeline',
  brand: 'Brand',
  assetType: 'Asset Type',
  inspectionType: 'Inspection Type',
  fixed: 'Fixed',
  inRepair: 'In Repair',
  validating: 'Validating',
  user: 'User',
  users: 'Users',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone Number',
  customerAccount: 'Customer Account',
  customerName: 'Customer Name',
  customerShorthandName: 'Customer Shorthand Name',
  contactName: 'Contact Name',
  contactNumber: 'Contact Number',
  contactEmail: 'Contact Email',
  licenseStartDate: 'License Start Date',
  licenseEndDate: 'License End Date',
  licenseLength: 'License Length',
  inMonths: 'in months',
  assetLimit: 'Asset Limit',
  actions: 'Actions',
  customerAdminAccount: 'Customer Admin Account',
  customerAdminAccounts: 'Customer Admin Accounts',
  username: 'Username',
  dateCreated: 'Date Created',
  created: 'Created',
  deviceId: 'Device ID',
  obdDevice: 'OBD Device',
  assetDetails: 'Asset Details',
  groups: 'Groups',
  resolvedIssues: 'Resolved Issues',
  viewingIssuesOf: 'Viewing issues of',
  active: 'Active',
  inactive: 'Inactive',
  started: 'Started',
  completed: 'Completed',
  checklist: 'Checklist',
  checklistHistory: 'Checklist History',
  workingComponents: 'Working Components',
  issuesReported: 'Issues Reported',
  importUsers: 'Import Users',
  importAssets: 'Import Assets',
  file: 'File',
  message: 'Message',
  messages: 'Messages',
}

export const ALL_LABELS = {
  ...LABELS,
  ...ACTIONS,
  ...MENU
}
