export const REGEX = {
  WHOLE: '^[0-9]*$',
  ALPHANUMERIC: /^[A-Za-z0-9\u00f1\u00d1]+$/,
  ALPHANUMERIC_WITH_UNDERSCORE: /^[A-Za-z0-9_\u00f1\u00d1]*$/,
  ALPHANUMERIC_WITH_SPACE: /^[A-Za-z0-9 \u00f1\u00d1]*$/,
  ALPHANUMERIC_WITH_DASH: /^[A-Za-z0-9-\u00f1\u00d1]*$/,
  ALPHANUMERIC_WITH_DASH_SPACE: /^[A-Za-z0-9- \u00f1\u00d1]*$/,
  CONTACT_NUMBER: '^[0-9,/-]{0,100}$',
  NUMERIC_WITH_DASH: '^[0-9-]*$',
  AMOUNTS: '^-?[0-9]*\\.?[0-9]*$',
  ALPHA_SPACE_PERIOD: /^[a-zA-Z. \u00f1\u00d1]+$/,
  ALPHA_UNDERSCORE_DASH_SPACE: /^[-\w\s\u00f1\u00d1]+$/,
  ALPHANUMERIC_UNDERSCORE_DASH: /^[\w-]+$/,
  NUMERIC: /^\d*$/,
};
